"use client";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeOffer, TypeOffers } from "@/types/jsonld/jsonld.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  getOfferByIdThunk,
  getOffersByIdsThunk,
  getOffersThunk,
} from "@/store/thunks/jsonld/OfferThunk";

export interface OfferState {
  offers: TypeOffers | null;
  offersSelected: TypeOffers | null;
  offer: TypeOffer | null;
  categoriesSelect: string[] | null;
  fuelsSelect: string[] | null;
  loadingOffer: "idle" | "pending" | "succeeded" | "failed";
  loadingOffers: "idle" | "pending" | "succeeded" | "failed";
  loadingSelected: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: OfferState = {
  offers: null,
  offersSelected: null,
  offer: null,
  categoriesSelect: null,
  fuelsSelect: null,
  loadingOffer: "idle",
  loadingOffers: "idle",
  loadingSelected: "idle",
  error: null,
} satisfies OfferState;

const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    resetOfferState: (state) => {
      state.offer = null;
    },
    adminCategoriesSelect: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.categoriesSelect = [...action.payload];
      } else {
        state.categoriesSelect = [];
      }
    },
    adminFuelsSelect: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.fuelsSelect = [...action.payload];
      } else {
        state.fuelsSelect = [];
      }
    },
    resetAdminCategoriesSelect: (state) => {
      state.categoriesSelect = [];
    },
    resetAdminFuelsSelect: (state) => {
      state.fuelsSelect = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOffersThunk.pending, (state) => {
        state.loadingOffers = "pending";
        state.error = null;
      })
      .addCase(getOffersThunk.fulfilled, (state, action: PayloadAction<TypeOffers | null>) => {
        state.loadingOffers = "succeeded";
        state.offers = action.payload;
      })
      .addCase(getOffersThunk.rejected, (state, action) => {
        state.loadingOffers = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(getOfferByIdThunk.pending, (state) => {
        state.loadingOffer = "pending";
        state.error = null;
      })
      .addCase(getOfferByIdThunk.fulfilled, (state, action: PayloadAction<TypeOffer | null>) => {
        state.loadingOffer = "succeeded";
        state.offer = action.payload;
      })
      .addCase(getOfferByIdThunk.rejected, (state, action) => {
        state.loadingOffer = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(getOffersByIdsThunk.pending, (state) => {
        state.loadingSelected = "pending";
        state.error = null;
      })
      .addCase(getOffersByIdsThunk.fulfilled, (state, action: PayloadAction<TypeOffers | null>) => {
        state.loadingSelected = "succeeded";
        state.offersSelected = action.payload;
      })
      .addCase(getOffersByIdsThunk.rejected, (state, action) => {
        state.loadingSelected = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export const { resetOfferState } = offerSlice.actions;
export const { adminCategoriesSelect } = offerSlice.actions;
export const { resetAdminCategoriesSelect } = offerSlice.actions;
export const { adminFuelsSelect } = offerSlice.actions;
export const { resetAdminFuelsSelect } = offerSlice.actions;
export default offerSlice.reducer;
