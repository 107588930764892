"use client";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createContactThunk,
  deleteContactThunk,
  getContactByIdThunk,
  getContactByLocalizationIdThunk,
  getContactsThunk,
  updateContactLocalizationIDThunk,
  updateContactLocalizationThunk,
} from "@/store/thunks/data/ContactThunks";
import { TypeContact, TypeContacts } from "@/types/system/contact.types";

export interface ContactState {
  contact: TypeContact | null;
  updateContact: TypeContact | null;
  contacts: TypeContact[] | null;
  contactLoading: "idle" | "pending" | "succeeded" | "failed";
  updateContactLoading: "idle" | "pending" | "succeeded" | "failed";
  contactsLoading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: ContactState = {
  contact: null,
  updateContact: null,
  contacts: null,
  contactLoading: "idle",
  updateContactLoading: "idle",
  contactsLoading: "idle",
  error: null,
} satisfies ContactState;

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContactsThunk.pending, (state) => {
        state.contactsLoading = "pending";
        state.error = null;
      })
      .addCase(getContactsThunk.fulfilled, (state, action: PayloadAction<TypeContacts | null>) => {
        state.contactsLoading = "succeeded";
        state.contacts = action.payload;
      })
      .addCase(getContactsThunk.rejected, (state, action) => {
        state.contactsLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(getContactByIdThunk.pending, (state) => {
        state.contactLoading = "pending";
        state.error = null;
      })
      .addCase(
        getContactByIdThunk.fulfilled,
        (state, action: PayloadAction<TypeContact | null>) => {
          state.contactLoading = "succeeded";
          state.contact = action.payload;
        },
      )
      .addCase(getContactByIdThunk.rejected, (state, action) => {
        state.contactLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(getContactByLocalizationIdThunk.pending, (state) => {
        state.contactLoading = "pending";
        state.error = null;
      })
      .addCase(
        getContactByLocalizationIdThunk.fulfilled,
        (state, action: PayloadAction<TypeContact | null>) => {
          state.contactLoading = "succeeded";
          state.contact = action.payload;
        },
      )
      .addCase(getContactByLocalizationIdThunk.rejected, (state, action) => {
        state.contactLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(createContactThunk.pending, (state) => {
        state.contactLoading = "pending";
        state.error = null;
      })
      .addCase(createContactThunk.fulfilled, (state, action: PayloadAction<TypeContact | null>) => {
        state.contactLoading = "succeeded";
        state.contact = action.payload;
      })
      .addCase(createContactThunk.rejected, (state, action) => {
        state.contactLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(updateContactLocalizationIDThunk.pending, (state) => {
        state.updateContactLoading = "pending";
        state.error = null;
      })
      .addCase(
        updateContactLocalizationIDThunk.fulfilled,
        (state, action: PayloadAction<TypeContact | null>) => {
          state.updateContactLoading = "succeeded";
          state.updateContact = action.payload;
        },
      )
      .addCase(updateContactLocalizationIDThunk.rejected, (state, action) => {
        state.updateContactLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(updateContactLocalizationThunk.pending, (state) => {
        state.updateContactLoading = "pending";
        state.error = null;
      })
      .addCase(
        updateContactLocalizationThunk.fulfilled,
        (state, action: PayloadAction<TypeContact | null>) => {
          state.updateContactLoading = "succeeded";
          state.updateContact = action.payload;
        },
      )
      .addCase(updateContactLocalizationThunk.rejected, (state, action) => {
        state.updateContactLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(deleteContactThunk.pending, (state) => {
        state.contactLoading = "pending";
        state.error = null;
      })
      .addCase(deleteContactThunk.fulfilled, (state, action: PayloadAction<TypeContact | null>) => {
        state.contactLoading = "succeeded";
        state.contact = action.payload;
      })
      .addCase(deleteContactThunk.rejected, (state, action) => {
        state.contactLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export default contactSlice.reducer;
