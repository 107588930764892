import { createAsyncThunk } from "@reduxjs/toolkit";
import { TypeFaq, TypeFaqCreate, TypeFaqs, TypeFaqUpdate } from "@/types/data/faq.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createFaq,
  deleteFaq,
  getFaqById,
  getFaqs,
  updateFaq,
} from "@/store/actions/data/FaqActions";
import { RootState } from "@/store";

const getFaqsThunk = createAsyncThunk<
  TypeFaqs | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("Faq/FindAll", async (_, { rejectWithValue }) => {
  try {
    const faqs: TypeFaqs | null = await getFaqs();

    if (faqs) {
      return faqs;
    } else {
      return rejectWithValue({
        errors: [{ path: "faq", message: "Aucune FAQ trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const getFaqEditThunk = createAsyncThunk<
  TypeFaq | null,
  void,
  { state: RootState; rejectValue: TRejectValueErrorsThunk }
>("Faq/GetFaqEdit", async (_, { getState, rejectWithValue }) => {
  const state = getState().faqs;

  if (!state.faqEdit) {
    return rejectWithValue({
      errors: [{ path: "faqEdit", message: "Aucune FAQ sélectionnée dans le store" }],
    });
  }

  return state.faqEdit;
});

const selectFaqThunk = createAsyncThunk<
  TypeFaq | null,
  number | null,
  { state: RootState; rejectValue: TRejectValueErrorsThunk }
>("Faq/Select", async (faqId: number | null, { getState, rejectWithValue }) => {
  const state = getState();

  if (faqId === null) {
    return null;
  }

  if (!state.faqs) {
    return rejectWithValue({
      errors: [{ path: "faqs", message: "Aucune FAQ disponible dans le store" }],
    });
  }

  const faq = state?.faqs?.faqs?.find((faq: TypeFaq) => faq.id === faqId);

  if (faq) {
    return faq;
  } else {
    return rejectWithValue({
      errors: [{ path: "faq", message: "FAQ non trouvée dans le store" }],
    });
  }
});

const getFaqByIdThunk = createAsyncThunk<
  TypeFaq | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("Faq/GetFaqById", async (faqId, { rejectWithValue }) => {
  try {
    const faq = await getFaqById(faqId);
    return faq;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue({
        errors: [{ path: "faq", message: error.message }],
      });
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const createFaqThunk = createAsyncThunk<
  TypeFaq,
  TypeFaqCreate,
  { rejectValue: TRejectValueErrorsThunk }
>("Faq/Create", async (data: TypeFaqCreate, { rejectWithValue }) => {
  try {
    const faq: TypeFaq = await createFaq(data);

    return faq;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const updateFaqThunk = createAsyncThunk<
  TypeFaq,
  { id: number; data: TypeFaqUpdate },
  { rejectValue: TRejectValueErrorsThunk }
>("Faq/Update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const updatedFaq: TypeFaq = await updateFaq({ id, data });

    return updatedFaq;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const deleteFaqThunk = createAsyncThunk<number, number, { rejectValue: TRejectValueErrorsThunk }>(
  "Faq/Delete",
  async (faqId: number, { rejectWithValue }) => {
    try {
      await deleteFaq(faqId);
      return faqId;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(JSON.parse(error.message));
      } else {
        return rejectWithValue({
          errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
        });
      }
    }
  },
);

export {
  getFaqsThunk,
  getFaqByIdThunk,
  getFaqEditThunk,
  selectFaqThunk,
  createFaqThunk,
  updateFaqThunk,
  deleteFaqThunk,
};
