import { createAsyncThunk } from "@reduxjs/toolkit";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createContact,
  deleteContact,
  getContactById,
  getContactByLocalizationId,
  getContacts,
  updateContactLocalization,
  updateContactLocalizationId,
} from "@/store/actions/data/ContactActions";
import { TypeContact, TypeContactCreate, TypeContacts } from "@/types/system/contact.types";

const getContactsThunk = createAsyncThunk<
  TypeContacts | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/Get", async (_, { rejectWithValue }) => {
  try {
    const contacts = await getContacts();

    if (contacts) {
      return contacts;
    } else {
      return rejectWithValue({
        errors: [
          { path: "Contact", message: "Une erreur au chargement des contacts est survenue" },
        ],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const getContactByIdThunk = createAsyncThunk<
  TypeContact | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/GetById", async (id: number, { rejectWithValue }) => {
  try {
    const contacts = await getContactById(id);

    if (contacts) {
      return contacts;
    } else {
      return rejectWithValue({
        errors: [
          { path: "Contact", message: "Une erreur au chargement des contacts est survenue" },
        ],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const getContactByLocalizationIdThunk = createAsyncThunk<
  TypeContact | null,
  string,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/GetByLocalizationID", async (id: string, { rejectWithValue }) => {
  try {
    const contacts = await getContactByLocalizationId(id);

    if (contacts) {
      return contacts;
    } else {
      return rejectWithValue({
        errors: [{ path: "Contact", message: "Une erreur au chargement du contact est survenue" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const createContactThunk = createAsyncThunk<
  TypeContact | null,
  TypeContactCreate,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/Create", async (contact, { rejectWithValue }) => {
  try {
    const taskContact = await createContact(contact);

    if (taskContact) {
      return taskContact;
    } else {
      return rejectWithValue({
        errors: [{ path: "Contact", message: "Une erreur au contact est survenue" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const deleteContactThunk = createAsyncThunk<
  TypeContact | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/Delete", async (id: number, { rejectWithValue }) => {
  try {
    const taskContact = await deleteContact(id);

    if (taskContact) {
      return taskContact;
    } else {
      return rejectWithValue({
        errors: [{ path: "Contact", message: "Une erreur au contact est survenue" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const updateContactLocalizationIDThunk = createAsyncThunk<
  TypeContact | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/UpdateContactLocalizationID", async (id: number, { rejectWithValue }) => {
  try {
    const contact = await updateContactLocalizationId(id);

    if (contact) {
      return contact;
    } else {
      return rejectWithValue({
        errors: [{ path: "Contact", message: "Une erreur au contact est survenue" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const updateContactLocalizationThunk = createAsyncThunk<
  TypeContact | null,
  { id: number; localization: { lat: number; lng: number } },
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/UpdateContactLocalization", async ({ id, localization }, { rejectWithValue }) => {
  try {
    const contact = await updateContactLocalization(id, localization);

    if (contact) {
      return contact;
    } else {
      return rejectWithValue({
        errors: [{ path: "Contact", message: "Une erreur au contact est survenue" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

export {
  getContactsThunk,
  getContactByIdThunk,
  getContactByLocalizationIdThunk,
  createContactThunk,
  deleteContactThunk,
  updateContactLocalizationIDThunk,
  updateContactLocalizationThunk,
};
