import { createAsyncThunk } from "@reduxjs/toolkit";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createVideoUrl,
  deleteVideoUrl,
  getAllVideos,
  getVideoUrl,
  updateVideoUrl,
} from "@/store/actions/data/VideoActions";
import { TypeVideo, TypeVideoCreate, TypeVideos, TypeVideoUpdate } from "@/types/data/video.types";

export const getAllVideosThunk = createAsyncThunk<
  TypeVideos | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("video/getAll", async (_, { rejectWithValue }) => {
  try {
    const videos = await getAllVideos();

    if (videos && videos.length > 0) {
      return videos;
    } else {
      return rejectWithValue({
        errors: [{ path: "Video", message: "Aucune vidéo trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

// GET Thunk pour récupérer une vidéo YouTube
export const getVideoByIdThunk = createAsyncThunk<
  TypeVideo | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("video/get", async (id, { rejectWithValue }) => {
  try {
    const video = await getVideoUrl(id);

    if (video) {
      return video.url;
    } else {
      return rejectWithValue({
        errors: [{ path: "Video", message: "Aucune vidéo trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

// CREATE Thunk pour créer une nouvelle vidéo YouTube
export const createVideoThunk = createAsyncThunk<
  TypeVideo | null,
  TypeVideoCreate,
  { rejectValue: TRejectValueErrorsThunk }
>("video/create", async (url, { rejectWithValue }) => {
  try {
    const newVideo = await createVideoUrl(url);

    if (newVideo) {
      return newVideo.url;
    } else {
      return rejectWithValue({
        errors: [{ path: "Video", message: "Erreur lors de la création de la vidéo" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

// UPDATE Thunk pour mettre à jour une vidéo YouTube
export const updateVideoThunk = createAsyncThunk<
  TypeVideo | null,
  TypeVideoUpdate,
  { rejectValue: TRejectValueErrorsThunk }
>("video/update", async (data, { rejectWithValue }) => {
  try {
    const updatedVideo = await updateVideoUrl(data);

    if (updatedVideo) {
      return updatedVideo.url;
    } else {
      return rejectWithValue({
        errors: [{ path: "Video", message: "Erreur lors de la mise à jour de la vidéo" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

// DELETE Thunk pour supprimer une vidéo YouTube
export const deleteVideoThunk = createAsyncThunk<
  null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("video/delete", async (id, { rejectWithValue }) => {
  try {
    const deletedVideo = await deleteVideoUrl(id);

    if (deletedVideo) {
      return null;
    } else {
      return rejectWithValue({
        errors: [{ path: "Video", message: "Erreur lors de la suppression de la vidéo" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});
