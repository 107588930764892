"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getBrands,
  getBrandsCategories,
  getBrandsWithAllDataBySlug,
  getModelWithVersionsBySlug,
  getOnlyBrandsBySlug,
} from "@/store/actions/data/BrandActions";
import { BrandType, CategoryType, ModelType } from "@/types/data/data.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";

/**
 * Thunk pour obtenir toutes les catégories de marques.
 */
export const fetchBrandsCategoriesThunk = createAsyncThunk<
  CategoryType[], // Type de retour
  void, // Pas d'argument
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("Brand/fetchCategories", async (_, { rejectWithValue }) => {
  try {
    const brandCategories = await getBrandsCategories();

    if (brandCategories) {
      return brandCategories;
    } else {
      return rejectWithValue({
        errors: [{ path: "Brand", message: "Aucune Catégorie" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

/**
 * Thunk pour obtenir toutes les marques.
 */
export const fetchBrandsthunk = createAsyncThunk<
  BrandType[], // Type de retour
  void, // Pas d'argument
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("Brand/fetchAll", async (_, { rejectWithValue }) => {
  try {
    const brand = await getBrands();

    if (brand) {
      return brand;
    } else {
      return rejectWithValue({
        errors: [{ path: "Brand", message: "Aucune marques trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

/**
 * Thunk pour obtenir une marque par son slug sans inclure les modèles.
 */
export const fetchBrandBySlugThunk = createAsyncThunk<
  BrandType | null, // Type de retour
  string, // Type d'argument (slug)
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("Brand/fetchBySlug", async (slug, { rejectWithValue }) => {
  try {
    const brand = await getOnlyBrandsBySlug(slug);

    if (brand) {
      return brand;
    } else {
      return rejectWithValue({
        errors: [{ path: "Brand", message: "Aucune marque trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

/**
 * Thunk pour obtenir une marque avec toutes ses données par son slug.
 */
export const fetchBrandWithAllDataBySlugThunk = createAsyncThunk<
  BrandType | null, // Type de retour
  string, // Type d'argument (slug)
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("Brand/fetchWithAllDataBySlug", async (slug, { rejectWithValue }) => {
  try {
    const brand = await getBrandsWithAllDataBySlug(slug);

    if (brand) {
      return brand;
    } else {
      return rejectWithValue({
        errors: [{ path: "Offer", message: "Aucune marque trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

/**
 * Thunk pour obtenir un modèle avec ses versions par son slug.
 */
export const fetchModelWithVersionsBySlugThunk = createAsyncThunk<
  ModelType | null, // Type de retour
  string, // Type d'argument (slug)
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("Brand/Models/fetchWithVersionsBySlug", async (slug, { rejectWithValue }) => {
  try {
    const brand = await getModelWithVersionsBySlug(slug);

    if (brand) {
      return brand;
    } else {
      return rejectWithValue({
        errors: [{ path: "Offer", message: "Aucun modèle trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});
