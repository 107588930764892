import { createAsyncThunk } from "@reduxjs/toolkit";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import { createUser, getUserByEmail, getUsers } from "@/store/actions/data/UserActions";
import { TypeUser, TypeUsers } from "@/types/data/user.types";

const getUsersThunk = createAsyncThunk<
  TypeUsers | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/GetAll", async (_, { rejectWithValue }) => {
  try {
    const users = await getUsers();

    if (users) {
      return users;
    } else {
      return rejectWithValue({
        errors: [
          { path: "User", message: "Une erreur au chargement des utilisateurs est survenue" },
        ],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const getUserByEmailThunk = createAsyncThunk<
  TypeUser | null,
  string,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/Get", async (mail, { rejectWithValue }) => {
  try {
    const user = await getUserByEmail(mail);

    if (user) {
      return user;
    } else {
      return rejectWithValue({
        errors: [
          { path: "User", message: "Une erreur au chargement de l'utilisateur est survenue" },
        ],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const createUserThunk = createAsyncThunk<
  TypeUser | null,
  TypeUser,
  { rejectValue: TRejectValueErrorsThunk }
>("Contact/Create", async (data, { rejectWithValue }) => {
  try {
    const user = await createUser(data);

    if (user) {
      return user;
    } else {
      return rejectWithValue({
        errors: [
          { path: "User", message: "Une erreur au chargement de l'utilisateur est survenue" },
        ],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

export { getUsersThunk, getUserByEmailThunk, createUserThunk };
