"use client";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createUserThunk,
  getUserByEmailThunk,
  getUsersThunk,
} from "@/store/thunks/data/UserThunks";
import { TypeUser, TypeUsers } from "@/types/data/user.types";

export interface UserState {
  user: TypeUser | null;
  users: TypeUsers | null;
  userLoading: "idle" | "pending" | "succeeded" | "failed";
  usersLoading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: UserState = {
  user: null,
  users: null,
  userLoading: "idle",
  usersLoading: "idle",
  error: null,
} satisfies UserState;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersThunk.pending, (state) => {
        state.usersLoading = "pending";
        state.error = null;
      })
      .addCase(getUsersThunk.fulfilled, (state, action: PayloadAction<TypeUsers | null>) => {
        state.usersLoading = "succeeded";
        state.users = action.payload;
      })
      .addCase(getUsersThunk.rejected, (state, action) => {
        state.usersLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(getUserByEmailThunk.pending, (state) => {
        state.userLoading = "pending";
        state.error = null;
      })
      .addCase(getUserByEmailThunk.fulfilled, (state, action: PayloadAction<TypeUser | null>) => {
        state.userLoading = "succeeded";
        state.user = action.payload;
      })
      .addCase(getUserByEmailThunk.rejected, (state, action) => {
        state.userLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(createUserThunk.pending, (state) => {
        state.userLoading = "pending";
        state.error = null;
      })
      .addCase(createUserThunk.fulfilled, (state, action: PayloadAction<TypeUser | null>) => {
        state.userLoading = "succeeded";
        state.user = action.payload;
      })
      .addCase(createUserThunk.rejected, (state, action) => {
        state.userLoading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export default userSlice.reducer;
