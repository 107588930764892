import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchBrandBySlugThunk,
  fetchBrandsCategoriesThunk,
  fetchBrandsthunk,
  fetchBrandWithAllDataBySlugThunk,
} from "@/store/thunks/data/BrandThunks";
import { BrandType, CategoryType, OptionType } from "@/types/data/data.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";

export interface BrandState {
  brands: BrandType[];
  categories: CategoryType[];
  categorieSelected: string;
  options: OptionType[];
  currentSlug: string | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: BrandState = {
  brands: [],
  categories: [],
  categorieSelected: "",
  options: [],
  currentSlug: null,
  loading: "idle",
  error: null,
} satisfies BrandState;

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setCategorieSelected: (state, action) => {
      state.categorieSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrandsCategoriesThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(
        fetchBrandsCategoriesThunk.fulfilled,
        (state, action: PayloadAction<CategoryType[]>) => {
          state.loading = "succeeded";
          state.categories = action.payload;
        },
      )
      .addCase(fetchBrandsCategoriesThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(fetchBrandsthunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(fetchBrandsthunk.fulfilled, (state, action: PayloadAction<BrandType[]>) => {
        state.loading = "succeeded";
        state.brands = action.payload;
      })
      .addCase(fetchBrandsthunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(fetchBrandBySlugThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(
        fetchBrandBySlugThunk.fulfilled,
        (state, action: PayloadAction<BrandType | null>) => {
          state.loading = "succeeded";
          if (action.payload) {
            state.currentSlug = action.payload.slug;
            const index = state.brands.findIndex((brand) => brand.slug === action.payload!.slug);
            if (index !== -1) {
              state.brands[index] = action.payload;
            } else {
              state.brands.push(action.payload);
            }
          }
        },
      )
      .addCase(fetchBrandBySlugThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(fetchBrandWithAllDataBySlugThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(
        fetchBrandWithAllDataBySlugThunk.fulfilled,
        (state, action: PayloadAction<BrandType | null>) => {
          state.loading = "succeeded";
          if (action.payload) {
            state.currentSlug = action.payload.slug;
            const index = state.brands.findIndex((brand) => brand.slug === action.payload!.slug);
            if (index !== -1) {
              state.brands[index] = action.payload;
            } else {
              state.brands.push(action.payload);
            }
          }
        },
      )
      .addCase(fetchBrandWithAllDataBySlugThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export const { setCategorieSelected } = brandSlice.actions;
export default brandSlice.reducer;
