import { createSlice } from "@reduxjs/toolkit";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import { EngineType } from "@/types/data/data.types";
import { getEngineOfferThunk, getEnginesOfferThunk } from "@/store/thunks/data/EngineOfferThunks";

export interface EngineOfferState {
  enginesOffer: string[] | null;
  engineOffer: EngineType | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: EngineOfferState = {
  enginesOffer: null,
  engineOffer: null,
  loading: "idle",
  error: null,
};

const engineOfferStateSlice = createSlice({
  name: "engineOffer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEnginesOfferThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getEnginesOfferThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.enginesOffer = action.payload;
      })
      .addCase(getEnginesOfferThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
    builder
      .addCase(getEngineOfferThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getEngineOfferThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.engineOffer = action.payload;
      })
      .addCase(getEngineOfferThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export default engineOfferStateSlice.reducer;
