import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TypePerson,
  TypePersonCreate,
  TypePersons,
  TypePersonUpdate,
} from "@/types/jsonld/jsonld.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createPerson,
  deletePerson,
  getPersonById,
  getPersons,
  updatePerson,
} from "@/store/actions/jsonld/PersonActions";
import { RootState } from "@/store";

const getPersonsThunk = createAsyncThunk<
  TypePersons | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("Person/FindAll", async (_, { rejectWithValue }) => {
  try {
    const persons: TypePersons | null = await getPersons();

    if (persons) {
      return persons;
    } else {
      return rejectWithValue({
        errors: [{ path: "person", message: "Aucune person trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const getPersonEditThunk = createAsyncThunk<
  TypePerson | null, // Type de retour (TypePerson ou null)
  void, // Aucun paramètre
  { state: RootState; rejectValue: TRejectValueErrorsThunk }
>("Person/GetPersonEdit", async (_, { getState, rejectWithValue }) => {
  const state = getState().persons;

  if (!state.personEdit) {
    return rejectWithValue({
      errors: [{ path: "personEdit", message: "Aucune personne sélectionnée dans le store" }],
    });
  }

  return state.personEdit;
});

const selectPersonThunk = createAsyncThunk<
  TypePerson | null, // Type de retour (TypePerson ou null)
  number | null, // Paramètre (ID de la personne ou null)
  { state: RootState; rejectValue: TRejectValueErrorsThunk }
>("Person/Select", async (personId: number | null, { getState, rejectWithValue }) => {
  const state = getState();

  if (personId === null) {
    return null;
  }

  if (!state.persons) {
    return rejectWithValue({
      errors: [{ path: "persons", message: "Aucune personne disponible dans le store" }],
    });
  }

  const person = state?.persons?.persons?.find((person: TypePerson) => person.id === personId);

  if (person) {
    return person;
  } else {
    return rejectWithValue({
      errors: [{ path: "person", message: "Personne non trouvée dans le store" }],
    });
  }
});

const getPersonByIdThunk = createAsyncThunk<
  TypePerson | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("Person/GetPersonById", async (personId, { rejectWithValue }) => {
  try {
    const person = await getPersonById(personId);
    return person;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue({
        errors: [{ path: "person", message: error.message }],
      });
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const createPersonThunk = createAsyncThunk<
  TypePerson,
  TypePersonCreate,
  { rejectValue: TRejectValueErrorsThunk }
>("Person/Create", async (data: TypePersonCreate, { rejectWithValue }) => {
  try {
    const person: TypePerson = await createPerson(data);

    return person;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const updatePersonThunk = createAsyncThunk<
  TypePerson,
  { id: number; data: TypePersonUpdate },
  { rejectValue: TRejectValueErrorsThunk }
>("Person/Update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const updatedPerson: TypePerson = await updatePerson({ id, data });

    return updatedPerson;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const deletePersonThunk = createAsyncThunk<
  number, // Retour (l'ID de la personne supprimée)
  number, // Param (l'ID de la personne à supprimer)
  { rejectValue: TRejectValueErrorsThunk }
>("Person/Delete", async (personId: number, { rejectWithValue }) => {
  try {
    await deletePerson(personId);
    return personId;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

export {
  getPersonsThunk,
  getPersonByIdThunk,
  getPersonEditThunk,
  selectPersonThunk,
  createPersonThunk,
  updatePersonThunk,
  deletePersonThunk,
};
