"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { EngineType } from "@/types/data/data.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import { getEngineOfferById, getEnginesOffer } from "@/store/actions/data/EngineOfferActions";

/**
 * Thunk pour obtenir toutes les options.
 */
export const getEnginesOfferThunk = createAsyncThunk<
  string[] | null, // Type de retour
  void, // Pas d'argument
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("Engines/FindAll", async (_, { rejectWithValue }) => {
  try {
    const engines = await getEnginesOffer();

    // Filtrer les slugs uniques
    return Array.from(new Set(engines.map((engine: EngineType) => engine.fuel)));
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

/**
 * Thunk pour obtenir une option.
 */
export const getEngineOfferThunk = createAsyncThunk<
  EngineType | null, // Type de retour
  number, // Pas d'argument
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("Engines/Find", async (id, { rejectWithValue }) => {
  try {
    return await getEngineOfferById(id);
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});
