"use client";

import { createSlice } from "@reduxjs/toolkit";
import { TypeLocalBusiness, TypeLocalBusinesses } from "@/types/jsonld/jsonld.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  getLocalBusinessByIdThunk,
  getLocalBusinessesThunk,
} from "@/store/thunks/jsonld/LocalBusinessThunk";

export interface LocalBusinessState {
  localBusinesses: TypeLocalBusinesses | null;
  localBusiness: TypeLocalBusiness | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: LocalBusinessState = {
  localBusinesses: null,
  localBusiness: null,
  loading: "idle",
  error: null,
} satisfies LocalBusinessState;

const localBusinessSlice = createSlice({
  name: "LocalBusiness",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocalBusinessesThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getLocalBusinessesThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.localBusinesses = action.payload;
      })
      .addCase(getLocalBusinessesThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(getLocalBusinessByIdThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getLocalBusinessByIdThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.localBusiness = action.payload;
      })
      .addCase(getLocalBusinessByIdThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export default localBusinessSlice.reducer;
