"use client";

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/data/UserSlice";
import contactReducer from "./slices/data/ContactSlice";
import carReducer from "./slices/data/CarSlice";
import enginesOfferReducer from "./slices/data/EngineOfferSlice";
import brandReducer from "./slices/data/BrandSlice";
import organizationReducer from "./slices/jsonld/OrganizationSlice";
import localBusinessesReducer from "./slices/jsonld/LocalBusinessSlice";
import offersReducer from "./slices/jsonld/OfferSlice";
import optionsReprogrammationReducer from "./slices/data/OptionReprogrammationSlice";
import personsReducer from "./slices/jsonld/PersonSlice";
import faqsReducer from "./slices/data/FaqSlice";
import categoryFaqsReducer from "./slices/data/CategoryFaqSlice";
import videosFaqsReducer from "./slices/data/VideoSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    users: userReducer,
    contact: contactReducer,
    contacts: contactReducer,
    car: carReducer,
    enginesOffer: enginesOfferReducer,
    brand: brandReducer,
    optionsReprogrammation: optionsReprogrammationReducer,
    organizations: organizationReducer,
    localBusinesses: localBusinessesReducer,
    offer: offersReducer,
    offers: offersReducer,
    offersSelected: offersReducer,
    persons: personsReducer,
    faq: faqsReducer,
    faqs: faqsReducer,
    categoryFaqs: categoryFaqsReducer,
    videos: videosFaqsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
