"use client";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getOptionReprogrammationThunk,
  getOptionsReprogrammationThunk,
} from "@/store/thunks/data/OptionReprogrammationThunks";
import { OptionType } from "@/types/data/data.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";

export interface OptionReprogrammationState {
  optionsReprogrammation: string[] | null;
  optionReprogrammation: OptionType | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: OptionReprogrammationState = {
  optionsReprogrammation: [],
  optionReprogrammation: null,
  loading: "idle",
  error: null,
};

const optionReprogrammationSlice = createSlice({
  name: "optionsReprogrammation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOptionsReprogrammationThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(
        getOptionsReprogrammationThunk.fulfilled,
        (state, action: PayloadAction<string[] | null>) => {
          state.loading = "succeeded";
          state.optionsReprogrammation = action.payload;
        },
      )
      .addCase(getOptionsReprogrammationThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload ?? null;
      });
    builder
      .addCase(getOptionReprogrammationThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(
        getOptionReprogrammationThunk.fulfilled,
        (state, action: PayloadAction<OptionType | null>) => {
          state.loading = "succeeded";
          state.optionReprogrammation = action.payload;
        },
      )
      .addCase(getOptionReprogrammationThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload ?? null;
      });
  },
});

export default optionReprogrammationSlice.reducer;
