"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TypeLocalBusiness,
  TypeLocalBusinessCreate,
  TypeLocalBusinesses,
  TypeLocalBusinessUpdate,
} from "@/types/jsonld/jsonld.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createLocalBusiness,
  deleteLocalBusiness,
  getLocalBusinessById,
  getLocalBusinesses,
  updateLocalBusiness,
} from "@/store/actions/jsonld/LocalBusinessActions";

/**
 * @async
 * @function getLocalBusinessesThunk
 * @description Récupère toutes les organisations en utilisant une thunk asynchrone.
 * Cette fonction envoie une requête pour récupérer la liste des organisations.
 * Si aucune organisation n'est trouvée ou si une erreur survient, la fonction gère ces cas via `rejectWithValue'.
 *
 * @param {object} thunkAPI - L'objet thunkAPI fourni par Redux Toolkit contenant des méthodes comme `rejectWithValue'.
 * @param {function} thunkAPI.rejectWithValue - Fonction pour rejeter la promesse avec une valeur personnalisée en cas d'erreur.
 *
 * @returns {Promise<TypeLocalBusinesses | null>} Une promesse qui se résout avec la liste des organisations ou `null` si aucune organisation n'est trouvée.
 *
 * @throws {TRejectValueErrorsThunk} Rejette avec des erreurs personnalisées en cas d'échec, soit des erreurs connues sous forme de JSON, soit un message d'erreur inconnu.
 */
const getLocalBusinessesThunk = createAsyncThunk<
  TypeLocalBusinesses | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("LocalBusiness/FindAll", async (_, { rejectWithValue }) => {
  try {
    const localBusinesses: TypeLocalBusinesses | null = await getLocalBusinesses();

    if (localBusinesses) {
      return localBusinesses;
    } else {
      return rejectWithValue({
        errors: [{ path: "LocalBusiness", message: "Aucune organisation trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const getLocalBusinessByIdThunk = createAsyncThunk<
  TypeLocalBusiness | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("LocalBusiness/FindById", async (id, { rejectWithValue }) => {
  try {
    const localBusiness: TypeLocalBusiness | null = await getLocalBusinessById(id);

    if (localBusiness) {
      return localBusiness;
    } else {
      return rejectWithValue({
        errors: [{ path: "LocalBusiness", message: "Organisation non trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const createLocalBusinessThunk = createAsyncThunk<
  TypeLocalBusiness,
  TypeLocalBusinessCreate,
  { rejectValue: TRejectValueErrorsThunk }
>("LocalBusiness/Create", async (data: TypeLocalBusinessCreate, { rejectWithValue }) => {
  try {
    const localBusiness: TypeLocalBusiness = await createLocalBusiness(data);

    return localBusiness;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const updateLocalBusinessThunk = createAsyncThunk<
  TypeLocalBusiness,
  { id: number; data: TypeLocalBusinessUpdate },
  { rejectValue: TRejectValueErrorsThunk }
>("LocalBusiness/Update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const updatedLocalBusiness: TypeLocalBusiness = await updateLocalBusiness(id, data);

    return updatedLocalBusiness;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const deleteLocalBusinessThunk = createAsyncThunk<
  number, // Retour (l'ID de la personne supprimée)
  number, // Param (l'ID de la personne à supprimer)
  { rejectValue: TRejectValueErrorsThunk }
>("Person/Delete", async (localBusinessId: number, { rejectWithValue }) => {
  try {
    await deleteLocalBusiness(localBusinessId);
    return localBusinessId;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

export {
  getLocalBusinessesThunk,
  getLocalBusinessByIdThunk,
  createLocalBusinessThunk,
  updateLocalBusinessThunk,
  deleteLocalBusinessThunk,
};
