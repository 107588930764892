import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypeCategoryFaq, TypeCategoryFaqs } from "@/types/data/faq.types";
import {
  createCategoryFaqThunk,
  deleteCategoryFaqThunk,
  getCategoryFaqByIdThunk,
  getCategoryFaqsThunk,
  updateCategoryFaqThunk,
} from "@/store/thunks/data/CategoryFaqThunks";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";

export interface CategoryFaqState {
  categoryFaq: TypeCategoryFaq | null;
  categoryFaqs: TypeCategoryFaqs | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: CategoryFaqState = {
  categoryFaq: null,
  categoryFaqs: null,
  loading: "idle",
  error: null,
};

const categoryFaqSlice = createSlice({
  name: "categoryFaq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryFaqsThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(
        getCategoryFaqsThunk.fulfilled,
        (state, action: PayloadAction<TypeCategoryFaqs | null>) => {
          state.loading = "succeeded";
          state.categoryFaqs = action.payload;
        },
      )
      .addCase(getCategoryFaqsThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "categoryFaqs", message: "Erreur de chargement des catégories de FAQ" }],
        };
      })
      .addCase(
        getCategoryFaqByIdThunk.fulfilled,
        (state, action: PayloadAction<TypeCategoryFaq | null>) => {
          state.categoryFaq = action.payload;
        },
      )
      .addCase(getCategoryFaqByIdThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "categoryFaq", message: "Erreur de chargement de la catégorie de FAQ" }],
        };
      })
      .addCase(
        createCategoryFaqThunk.fulfilled,
        (state, action: PayloadAction<TypeCategoryFaq | null>) => {
          state.loading = "succeeded";
          state.categoryFaq = action.payload;
        },
      )
      .addCase(createCategoryFaqThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "categoryFaq", message: "Erreur de création de la catégorie de FAQ" }],
        };
      })
      .addCase(
        updateCategoryFaqThunk.fulfilled,
        (state, action: PayloadAction<TypeCategoryFaq | null>) => {
          state.loading = "succeeded";
          state.categoryFaq = action.payload;
        },
      )
      .addCase(updateCategoryFaqThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [
            { path: "categoryFaq", message: "Erreur de mise à jour de la catégorie de FAQ" },
          ],
        };
      })
      .addCase(deleteCategoryFaqThunk.fulfilled, (state, action: PayloadAction<number>) => {
        if (state.categoryFaqs) {
          state.categoryFaqs = state.categoryFaqs.filter(
            (category) => category.id !== action.payload,
          );
        }
      })
      .addCase(deleteCategoryFaqThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [
            { path: "categoryFaq", message: "Erreur de suppression de la catégorie de FAQ" },
          ],
        };
      });
  },
});

export default categoryFaqSlice.reducer;
