import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypePerson, TypePersons } from "@/types/jsonld/jsonld.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createPersonThunk,
  deletePersonThunk,
  getPersonByIdThunk,
  getPersonEditThunk,
  getPersonsThunk,
  selectPersonThunk,
  updatePersonThunk,
} from "@/store/thunks/jsonld/PersonThunk";

export interface PersonState {
  person: TypePerson | null;
  persons: TypePersons | null;
  personEdit: TypePerson | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: PersonState = {
  person: null,
  persons: null,
  personEdit: null,
  loading: "idle",
  error: null,
};

const personSlice = createSlice({
  name: "person",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle getPersonsThunk
      .addCase(getPersonsThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getPersonsThunk.fulfilled, (state, action: PayloadAction<TypePersons | null>) => {
        state.loading = "succeeded";
        state.persons = action.payload;
      })
      .addCase(getPersonsThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      // Handle getPersonEditThunk
      .addCase(getPersonEditThunk.fulfilled, (state, action: PayloadAction<TypePerson | null>) => {
        state.personEdit = action.payload;
      })
      .addCase(getPersonEditThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      // Handle selectPersonThunk
      .addCase(selectPersonThunk.fulfilled, (state, action: PayloadAction<TypePerson | null>) => {
        state.personEdit = action.payload;
      })
      .addCase(selectPersonThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      // Handle createPersonThunk
      .addCase(createPersonThunk.fulfilled, (state, action: PayloadAction<TypePerson | null>) => {
        state.person = action.payload;
      })
      .addCase(createPersonThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      // Handle updatePersonThunk
      .addCase(updatePersonThunk.fulfilled, (state, action: PayloadAction<TypePerson | null>) => {
        state.personEdit = action.payload;
      })
      .addCase(updatePersonThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      // Handle deletePersonThunk
      .addCase(deletePersonThunk.fulfilled, (state, action) => {
        if (state.persons) {
          state.persons = state.persons.filter((person) => person.id !== action.payload);
        }
      })
      .addCase(deletePersonThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      // Handle getPersonByIdThunk
      .addCase(getPersonByIdThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getPersonByIdThunk.fulfilled, (state, action) => {
        state.personEdit = action.payload;
        state.loading = "succeeded";
      })
      .addCase(getPersonByIdThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export default personSlice.reducer;
