import { createSlice } from "@reduxjs/toolkit";
import { getCarThunk } from "@/store/thunks/data/CarThunks";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import { TypeOffer } from "@/types/jsonld/jsonld.types";

export interface CarState {
  car: any;
  option: TypeOffer | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: CarState = {
  car: null,
  option: null,
  loading: "idle",
  error: null,
};

const carSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setCurrentOption: (state, action) => {
      state.option = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCarThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getCarThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.car = action.payload;
      })
      .addCase(getCarThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export const { setCurrentOption } = carSlice.actions;
export default carSlice.reducer;
