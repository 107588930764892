import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createVideoThunk,
  deleteVideoThunk,
  getAllVideosThunk,
  getVideoByIdThunk,
  updateVideoThunk,
} from "@/store/thunks/data/VideoThunks";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import { TypeVideo, TypeVideos } from "@/types/data/video.types";

export interface VideoState {
  url: TypeVideo | null;
  urls: TypeVideos | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: VideoState = {
  url: null,
  urls: null,
  loading: "idle",
  error: null,
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllVideosThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getAllVideosThunk.fulfilled, (state, action: PayloadAction<TypeVideos | null>) => {
        state.loading = "succeeded";
        state.urls = action.payload;
      })
      .addCase(getAllVideosThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur lors de la récupération des vidéos" }],
        };
      })
      .addCase(getVideoByIdThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getVideoByIdThunk.fulfilled, (state, action: PayloadAction<TypeVideo | null>) => {
        state.loading = "succeeded";
        state.url = action.payload;
      })
      .addCase(getVideoByIdThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur lors de la récupération de la vidéo" }],
        };
      })
      .addCase(createVideoThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(createVideoThunk.fulfilled, (state, action: PayloadAction<TypeVideo | null>) => {
        state.loading = "succeeded";
        state.url = action.payload;
      })
      .addCase(createVideoThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur lors de la création de la vidéo" }],
        };
      })
      .addCase(updateVideoThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(updateVideoThunk.fulfilled, (state, action: PayloadAction<TypeVideo | null>) => {
        state.loading = "succeeded";
        state.url = action.payload;
      })
      .addCase(updateVideoThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur lors de la mise à jour de la vidéo" }],
        };
      })
      .addCase(deleteVideoThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(deleteVideoThunk.fulfilled, (state) => {
        state.loading = "succeeded";
        state.url = null;
      })
      .addCase(deleteVideoThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur lors de la suppression de la vidéo" }],
        };
      });
  },
});

export default videoSlice.reducer;
