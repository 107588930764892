import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TypeCategoryFaq,
  TypeCategoryFaqCreate,
  TypeCategoryFaqs,
  TypeCategoryFaqUpdate,
} from "@/types/data/faq.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createCategoryFaq,
  deleteCategoryFaq,
  getCategoryFaqById,
  getCategoryFaqs,
  updateCategoryFaq,
} from "@/store/actions/data/CategoryFaqActions";

/**
 * Thunk pour récupérer toutes les catégories de FAQ.
 */
export const getCategoryFaqsThunk = createAsyncThunk<
  TypeCategoryFaqs | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("CategoryFaq/FindAll", async (_, { rejectWithValue }) => {
  try {
    const categoryFaqs = await getCategoryFaqs();
    return categoryFaqs || null;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    }
    return rejectWithValue({
      errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
    });
  }
});

/**
 * Thunk pour récupérer une catégorie de FAQ par son ID.
 */
export const getCategoryFaqByIdThunk = createAsyncThunk<
  TypeCategoryFaq | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("CategoryFaq/GetById", async (categoryFaqId, { rejectWithValue }) => {
  try {
    const categoryFaq = await getCategoryFaqById(categoryFaqId);
    return categoryFaq || null;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue({
        errors: [{ path: "categoryFaq", message: error.message }],
      });
    }
    return rejectWithValue({
      errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
    });
  }
});

/**
 * Thunk pour créer une nouvelle catégorie de FAQ.
 */
export const createCategoryFaqThunk = createAsyncThunk<
  TypeCategoryFaq,
  TypeCategoryFaqCreate,
  { rejectValue: TRejectValueErrorsThunk }
>("CategoryFaq/Create", async (data, { rejectWithValue }) => {
  try {
    const categoryFaq = await createCategoryFaq(data);
    return categoryFaq;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    }
    return rejectWithValue({
      errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
    });
  }
});

/**
 * Thunk pour mettre à jour une catégorie de FAQ.
 */
export const updateCategoryFaqThunk = createAsyncThunk<
  TypeCategoryFaq,
  { id: number; data: TypeCategoryFaqUpdate },
  { rejectValue: TRejectValueErrorsThunk }
>("CategoryFaq/Update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const updatedCategoryFaq = await updateCategoryFaq({ id, data });
    return updatedCategoryFaq;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    }
    return rejectWithValue({
      errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
    });
  }
});

/**
 * Thunk pour supprimer une catégorie de FAQ par son ID.
 */
export const deleteCategoryFaqThunk = createAsyncThunk<
  number,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("CategoryFaq/Delete", async (categoryFaqId, { rejectWithValue }) => {
  try {
    await deleteCategoryFaq(categoryFaqId);
    return categoryFaqId;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    }
    return rejectWithValue({
      errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
    });
  }
});
