"use client";

import {createSlice} from "@reduxjs/toolkit";
import {TypeOrganizations} from "@/types/jsonld/jsonld.types";
import {TRejectValueErrorsThunk} from "@/types/store/thunk/thunk.types";
import {getOrganizationsThunk} from "@/store/thunks/jsonld/OrganizationThunk";

export interface OrganizationState {
  organizations: TypeOrganizations | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: OrganizationState = {
  organizations: null,
  loading: "idle",
  error: null,
} satisfies OrganizationState;

const organizationSlice = createSlice({
  name: "Organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationsThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getOrganizationsThunk.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.organizations = action.payload;
      })
      .addCase(getOrganizationsThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || { errors: [{ path: 'unknown', message: 'Erreur non gérée' }] };
      });
  },
});

export default organizationSlice.reducer;
