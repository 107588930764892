import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createFaqThunk,
  deleteFaqThunk,
  getFaqByIdThunk,
  getFaqEditThunk,
  getFaqsThunk,
  selectFaqThunk,
  updateFaqThunk,
} from "@/store/thunks/data/FaqThunks";
import { TypeFaq, TypeFaqs } from "@/types/data/faq.types";

export interface FaqState {
  faq: TypeFaq | null;
  faqs: TypeFaqs | null;
  faqEdit: TypeFaq | null;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: TRejectValueErrorsThunk | null;
}

const initialState: FaqState = {
  faq: null,
  faqs: null,
  faqEdit: null,
  loading: "idle",
  error: null,
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaqsThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getFaqsThunk.fulfilled, (state, action: PayloadAction<TypeFaqs | null>) => {
        state.loading = "succeeded";
        state.faqs = action.payload;
      })
      .addCase(getFaqsThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(getFaqEditThunk.fulfilled, (state, action: PayloadAction<TypeFaq | null>) => {
        state.faqEdit = action.payload;
      })
      .addCase(getFaqEditThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(selectFaqThunk.fulfilled, (state, action: PayloadAction<TypeFaq | null>) => {
        state.faqEdit = action.payload;
      })
      .addCase(selectFaqThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(createFaqThunk.fulfilled, (state, action: PayloadAction<TypeFaq | null>) => {
        state.faq = action.payload;
      })
      .addCase(createFaqThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(updateFaqThunk.fulfilled, (state, action: PayloadAction<TypeFaq | null>) => {
        state.faqEdit = action.payload;
      })
      .addCase(updateFaqThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(deleteFaqThunk.fulfilled, (state, action) => {
        if (state.faqs) {
          state.faqs = state.faqs.filter((faq: TypeFaq) => faq.id !== action.payload);
        }
      })
      .addCase(deleteFaqThunk.rejected, (state, action) => {
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      })
      .addCase(getFaqByIdThunk.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getFaqByIdThunk.fulfilled, (state, action: PayloadAction<TypeFaq | null>) => {
        state.faq = action.payload;
        state.loading = "succeeded";
      })
      .addCase(getFaqByIdThunk.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload || {
          errors: [{ path: "unknown", message: "Erreur non gérée" }],
        };
      });
  },
});

export default faqSlice.reducer;
