"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getOptionsReprogrammation,
  getOptionsReprogrammationById,
} from "@/store/actions/data/OptionReprogrammationActions";
import { OptionType } from "@/types/data/data.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";

/**
 * Thunk pour obtenir toutes les options.
 */
export const getOptionsReprogrammationThunk = createAsyncThunk<
  string[] | null, // Type de retour
  void, // Pas d'argument
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("options/FindAll", async (_, { rejectWithValue }) => {
  try {
    const options = await getOptionsReprogrammation();

    // Filtrer les slugs uniques
    return Array.from(new Set(options.map((option: OptionType) => option.slug)));
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

/**
 * Thunk pour obtenir une option.
 */
export const getOptionReprogrammationThunk = createAsyncThunk<
  OptionType | null, // Type de retour
  number, // Pas d'argument
  { rejectValue: TRejectValueErrorsThunk } // Type d'erreur
>("options/Find", async (id, { rejectWithValue }) => {
  try {
    return await getOptionsReprogrammationById(id);
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});
