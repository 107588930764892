'use client';

import { FC, ReactNode } from "react";
import { Provider } from "react-redux";
import store from "@/store";

interface TPageProps {
  children: ReactNode;
}

const Page: FC<TPageProps> = ({ children }: TPageProps) => {
  return <Provider store={store}>
      {children}
    </Provider>;
};

export default Page;
