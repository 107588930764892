import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TypeOffer,
  TypeOfferCreate,
  TypeOffers,
  TypeOfferUpdate,
} from "@/types/jsonld/jsonld.types";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import {
  createOffer,
  deleteOffer,
  getOfferById,
  getOffers,
  getOffersByIds,
  updateOffer,
} from "@/store/actions/jsonld/offer/OfferActions";

const getOffersThunk = createAsyncThunk<
  TypeOffers | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("Offer/FindAll", async (_, { rejectWithValue }) => {
  try {
    const offers: TypeOffers | null = await getOffers();

    if (offers) {
      return offers;
    } else {
      return rejectWithValue({
        errors: [{ path: "Offer", message: "Aucune offres trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const createOfferThunk = createAsyncThunk<
  TypeOffer | null,
  TypeOfferCreate,
  { rejectValue: TRejectValueErrorsThunk }
>("Offer/Create", async (data: TypeOfferCreate, { rejectWithValue }) => {
  try {
    const offer: TypeOffer | null = await createOffer(data);

    return offer;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const getOfferByIdThunk = createAsyncThunk<
  TypeOffer | null,
  number,
  { rejectValue: TRejectValueErrorsThunk }
>("Offer/FindById", async (id, { rejectWithValue }) => {
  try {
    const offer: TypeOffer | null = await getOfferById(id);

    if (offer) {
      return offer;
    } else {
      return rejectWithValue({
        errors: [{ path: "Offer", message: "Offres non trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const getOffersByIdsThunk = createAsyncThunk<
  TypeOffers | null,
  number[],
  { rejectValue: TRejectValueErrorsThunk }
>("Offer/FindByIds", async (ids, { rejectWithValue }) => {
  try {
    const offers: TypeOffers | null = await getOffersByIds(ids);

    if (offers) {
      return offers;
    } else {
      return rejectWithValue({
        errors: [{ path: "Offer", message: "Offres non trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const updateOfferThunk = createAsyncThunk<
  TypeOffer | null,
  { id: number; data: TypeOfferUpdate },
  { rejectValue: TRejectValueErrorsThunk }
>("Offer/Update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const offer: TypeOffer | null = await updateOffer(id, data);

    return offer;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

const deleteOfferThunk = createAsyncThunk<
  number, // Retour (l'ID de la personne supprimée)
  number, // Param (l'ID de la personne à supprimer)
  { rejectValue: TRejectValueErrorsThunk }
>("Offer/Delete", async (offerId: number, { rejectWithValue }) => {
  try {
    const offerIdDelete = await deleteOffer(offerId);

    if (offerIdDelete) {
      return offerIdDelete;
    } else {
      return rejectWithValue({
        errors: [{ path: "Offer", message: "Aucune offre trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

export {
  getOffersThunk,
  getOfferByIdThunk,
  getOffersByIdsThunk,
  createOfferThunk,
  updateOfferThunk,
  deleteOfferThunk,
};
