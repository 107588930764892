import { createAsyncThunk } from "@reduxjs/toolkit";
import { TRejectValueErrorsThunk } from "@/types/store/thunk/thunk.types";
import { getCar } from "@/store/actions/data/CarActions";

const getCarThunk = createAsyncThunk<any | null, number, { rejectValue: TRejectValueErrorsThunk }>(
  "Car/Find",
  async (id, { rejectWithValue }) => {
    try {
      const car = await getCar(id);

      if (car) {
        return car;
      } else {
        return rejectWithValue({
          errors: [{ path: "Car", message: "Aucune voiture trouvée" }],
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(JSON.parse(error.message));
      } else {
        return rejectWithValue({
          errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
        });
      }
    }
  },
);

export { getCarThunk };
