"use client";

import {createAsyncThunk} from "@reduxjs/toolkit";
import {TypeOrganizationCreate, TypeOrganizations} from "@/types/jsonld/jsonld.types";
import {TRejectValueErrorsThunk} from "@/types/store/thunk/thunk.types";
import {createOrganization, getOrganizations, updateOrganization} from "@/store/actions/jsonld/OrganizationActions";

/**
 * @async
 * @function getOrganizationsThunk
 * @description Récupère toutes les organisations en utilisant une thunk asynchrone.
 * Cette fonction envoie une requête pour récupérer la liste des organisations.
 * Si aucune organisation n'est trouvée ou si une erreur survient, la fonction gère ces cas via `rejectWithValue'.
 *
 * @param {object} thunkAPI - L'objet thunkAPI fourni par Redux Toolkit contenant des méthodes comme `rejectWithValue'.
 * @param {function} thunkAPI.rejectWithValue - Fonction pour rejeter la promesse avec une valeur personnalisée en cas d'erreur.
 *
 * @returns {Promise<TypeOrganizations | null>} Une promesse qui se résout avec la liste des organisations ou `null` si aucune organisation n'est trouvée.
 *
 * @throws {TRejectValueErrorsThunk} Rejette avec des erreurs personnalisées en cas d'échec, soit des erreurs connues sous forme de JSON, soit un message d'erreur inconnu.
 */
const getOrganizationsThunk = createAsyncThunk<
  TypeOrganizations | null,
  void,
  { rejectValue: TRejectValueErrorsThunk }
>("Organization/FindAll", async (_, { rejectWithValue }) => {
  try {
    const organizations: TypeOrganizations | null = await getOrganizations();

    if (organizations) {
      return organizations;
    } else {
      return rejectWithValue({
        errors: [{ path: "organization", message: "Aucune organisation trouvée" }],
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

/**
 * @async
 * @function createOrganizationThunk
 * @description Crée une organisation en utilisant une thunk asynchrone.
 * Cette fonction envoie une requête pour créer une organisation avec les données fournies.
 * Si la requête échoue, elle gère les erreurs et les retourne via `rejectWithValue'.
 *
 * @param {TypeOrganizationCreate} data - Les données nécessaires pour créer une nouvelle organisation.
 * @param {object} thunkAPI - L'objet thunkAPI fourni par Redux Toolkit contenant des méthodes comme `rejectWithValue'.
 * @param {function} thunkAPI.rejectWithValue - Fonction pour rejeter la promesse avec une valeur personnalisée en cas d'erreur.
 *
 * @returns {Promise<TypeOrganizations>} Une promesse qui se résout avec les informations de l'organisation créée.
 *
 * @throws {TRejectValueErrorsThunk} Rejette avec des erreurs personnalisées en cas d'échec, soit des erreurs connues sous forme de JSON, soit un message d'erreur inconnu.
 */
const createOrganizationThunk = createAsyncThunk<
  TypeOrganizations,
  TypeOrganizationCreate,
  { rejectValue: TRejectValueErrorsThunk }
>("Organization/Create", async (data: TypeOrganizationCreate, { rejectWithValue }) => {
  try {
    const organization: TypeOrganizations = await createOrganization(data);

    return organization;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

/**
 * @async
 * @function updateOrganizationThunk
 * @description Met à jour une organisation existante en utilisant une thunk asynchrone.
 * Cette fonction envoie une requête pour mettre à jour l'organisation spécifiée par l'identifiant `id` avec les nouvelles données fournies.
 * Si la requête échoue, elle gère les erreurs et les retourne via `rejectWithValue'.
 *
 * @param {object} arg - Un objet contenant l'identifiant de l'organisation à mettre à jour ainsi que les nouvelles données.
 * @param {number} arg.id - L'identifiant de l'organisation à mettre à jour.
 * @param {TypeOrganizationCreate} arg.data - Les nouvelles données pour mettre à jour l'organisation.
 * @param {object} thunkAPI - L'objet thunkAPI fourni par Redux Toolkit contenant des méthodes comme `rejectWithValue'.
 * @param {function} thunkAPI.rejectWithValue - Fonction pour rejeter la promesse avec une valeur personnalisée en cas d'erreur.
 *
 * @returns {Promise<TypeOrganizations>} Une promesse qui se résout avec les informations de l'organisation mise à jour.
 *
 * @throws {TRejectValueErrorsThunk} Rejette avec des erreurs personnalisées en cas d'échec, soit des erreurs connues sous forme de JSON, soit un message d'erreur inconnu.
 */
const updateOrganizationThunk = createAsyncThunk<
  TypeOrganizations,
  { id: number; data: TypeOrganizationCreate },
  { rejectValue: TRejectValueErrorsThunk }
>("Organization/Update", async ({ id, data }, { rejectWithValue }) => {
  try {
    const organization: TypeOrganizations = await updateOrganization(id, data);

    return organization;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(JSON.parse(error.message));
    } else {
      return rejectWithValue({
        errors: [{ path: "unknown", message: "Une erreur inconnue est survenue" }],
      });
    }
  }
});

export {
  getOrganizationsThunk,
  createOrganizationThunk,
  updateOrganizationThunk
}
